import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const [ d, mq ] = [document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.mainvisual();
    gsap.registerPlugin(ScrollTrigger);
    this.message();
  }
  
  mainvisual () {
    let Mv = document.querySelector('.mainvisual');
    window.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });
  }

  message() {
    gsap.fromTo(
      ".video_wrap",
      {
        opacity: "1",
      },
      {
        opacity: "0",
        scrollTrigger: {
          trigger: ".block-message",
          start: "top 100%",
          end: 'top 60%',
          scrub: true,
          // markers: true,
        },
      }
    );
    
    if(!mq.matches){
      gsap.fromTo(
        ".pic2",
        {
          bottom: "-120px",
        },
        {
          bottom: "-20px",
          scrollTrigger: {
            trigger: ".block-message",
            start: "top 100%",
            end: 'bottom 0%',
            scrub: true,
          },
        }
      );
      gsap.fromTo(
        ".pic3",
        {
          top: "24px",
        },
        {
          top: "-34px",
          scrollTrigger: {
            trigger: ".block-message",
            start: "top 100%",
            end: 'bottom 0%',
            scrub: true,
          },
        }
      );
      gsap.fromTo(
        ".pic4",
        {
          bottom: "-147px",
        },
        {
          bottom: "-20px",
          scrollTrigger: {
            trigger: ".block-message",
            start: "top 100%",
            end: 'bottom 0%',
            scrub: true,
          },
        }
      );
    }

    const fadeInItems = document.querySelectorAll('.scroll_orange');
    fadeInItems.forEach((fadeInItem) => {
      ScrollTrigger.create({
        trigger: fadeInItem,
        start: "top 70%",
        toggleClass: {
          targets: fadeInItem,
          className: "active",
        },
        once: true,
      });
    });
  }
}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}