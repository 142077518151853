const [ w ] = [window];
const domReady = TPL => {
  new aboutBLOCKS;
};

class aboutBLOCKS {
  constructor() {
    this.orangeText();
  }

  orangeText() {
    $(window).on('load scroll', function(){
      let scrollPosition = $(this).scrollTop() + $(this).height();
      let triggerPosition = $('.scroll_orange').offset().top + 200;
      if ( scrollPosition > triggerPosition) {
        $('.scroll_orange').addClass('active');
      }
    });
  }
}

export default function ABOUT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  $(w).on('load', domReady);
}